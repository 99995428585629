import React from 'react';
import Gallery from 'react-grid-gallery';
import officPic1 from '../images/gallery/_DSC0495.jpg';
import officPic1thumb from '../images/gallery/_DSC0495-thumb.jpg';
import officPic2 from '../images/gallery/_DSC0504.jpg';
import officPic2thumb from '../images/gallery/_DSC0504-thumb.jpg';
import officPic3 from '../images/gallery/_DSC0507.jpg';
import officPic3thumb from '../images/gallery/_DSC0507-thumb.jpg';
import officPic4 from '../images/gallery/_DSC0518.jpg';
import officPic4thumb from '../images/gallery/_DSC0518-thumb.jpg';
import officPic5 from '../images/gallery/_DSC0520.jpg';
import officPic5thumb from '../images/gallery/_DSC0520-thumb.jpg';
import officPic6 from '../images/gallery/DSC_9074.jpg';
import officPic6thumb from '../images/gallery/DSC_9074-thumb.jpg';
import officPic7 from '../images/gallery/DSC_9075.jpg';
import officPic7thumb from '../images/gallery/DSC_9075-thumb.jpg';
import officPic8 from '../images/gallery/DSC_9077.jpg';
import officPic8thumb from '../images/gallery/DSC_9077-thumb.jpg';
import officPic9 from '../images/gallery/DSC_9085.jpg';
import officPic9thumb from '../images/gallery/DSC_9085-thumb.jpg';
import officPic10 from '../images/gallery/DSC_9088.jpg';
import officPic10thumb from '../images/gallery/DSC_9088-thumb.jpg';
import officPic11 from '../images/gallery/DSC_9098.jpg';
import officPic11thumb from '../images/gallery/DSC_9098-thumb.jpg';
import officPic12 from '../images/gallery/DSC_9112.jpg';
import officPic12thumb from '../images/gallery/DSC_9112-thumb.jpg';
import officPic13 from '../images/gallery/DSC_9120.jpg';
import officPic13thumb from '../images/gallery/DSC_9120-thumb.jpg';
import officPic14 from '../images/gallery/DSC_9131.jpg';
import officPic14thumb from '../images/gallery/DSC_9131-thumb.jpg';
import officPic15 from '../images/gallery/DSC_9136.jpg';
import officPic15thumb from '../images/gallery/DSC_9136-thumb.jpg';
import officPic16 from '../images/gallery/DSC_91130.jpg';
import officPic16thumb from '../images/gallery/DSC_91130-thumb.jpg';
let GalleryComponent = () => {
    let IMAGES =
        [
            {
                src: officPic1,
                thumbnail: officPic1thumb,
                thumbnailWidth: 320,
                thumbnailHeight: 192,

            },
            {
                src: officPic2,
                thumbnail: officPic2thumb,
                thumbnailWidth: 320,
                thumbnailHeight: 192,

            },
            {
                src: officPic3,
                thumbnail: officPic3thumb,
                thumbnailWidth: 360,
                thumbnailHeight: 192,

            },
            {
                src: officPic4,
                thumbnail: officPic4thumb,
                thumbnailWidth: 220,
                thumbnailHeight: 248,

            },
            {
                src: officPic5,
                thumbnail: officPic5thumb,
                thumbnailWidth: 320,
                thumbnailHeight: 192,

            },
            {
                src: officPic6,
                thumbnail: officPic6thumb,
                thumbnailWidth: 320,
                thumbnailHeight: 192,

            },
            {
                src: officPic7,
                thumbnail: officPic7thumb,
                thumbnailWidth: 320,
                thumbnailHeight: 192,

            },
            {
                src: officPic8,
                thumbnail: officPic8thumb,
                thumbnailWidth: 320,
                thumbnailHeight: 192,

            },
            {
                src: officPic9,
                thumbnail: officPic9thumb,
                thumbnailWidth: 320,
                thumbnailHeight: 192,

            },
            {
                src: officPic10,
                thumbnail: officPic10thumb,
                thumbnailWidth: 320,
                thumbnailHeight: 192,

            },
            {
                src: officPic11,
                thumbnail: officPic11thumb,
                thumbnailWidth: 320,
                thumbnailHeight: 192,
            },
            {
                src: officPic12,
                thumbnail: officPic12thumb,
                thumbnailWidth: 320,
                thumbnailHeight: 192,

            },
            {
                src: officPic14,
                thumbnail: officPic14thumb,
                thumbnailWidth: 320,
                thumbnailHeight: 192,

            },
            {
                src: officPic15,
                thumbnail: officPic15thumb,
                thumbnailWidth: 220,
                thumbnailHeight: 248,

            },
            {
                src: officPic16,
                thumbnail: officPic16thumb,
                thumbnailWidth: 220,
                thumbnailHeight: 248,

            },
            {
                src: officPic13,
                thumbnail: officPic13thumb,
                thumbnailWidth: 320,
                thumbnailHeight: 192,

            }
        ];
    return (
        <div className="gallery-img max-w-6xl mx-auto px-4 sm:px-6">
            <div class="max-w-3xl mx-auto text-center pb-12 md:pb-16"><h2 class="h2 mb-4">Our workspace</h2><p class="text-xl text-gray-600 aos-init aos-animate" data-aos="zoom-y-out">Our workstations are equipped with high-performant systems to boost productivity, sit &amp; stand desks to switch positions while working, and high back mesh chairs for comfortable sitting</p></div>
            <Gallery className="rounded" images={IMAGES} margin={2} enableImageSelection={false} />
        </div>
    );
};
export default GalleryComponent;

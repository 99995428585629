import React from 'react';

import Header from '../partials/Header';
import HeroHome from '../partials/HeroHome';
import FeaturesHome from '../partials/Features';
import FeaturesBlocks from '../partials/FeaturesBlocks';
import Testimonials from '../partials/Testimonials';
import Newsletter from '../partials/Newsletter';
import Footer from '../partials/Footer';
import GalleryComponent from '../partials/GalleryComponent';

function Home() {
  return (
    <div className="flex flex-col min-h-screen overflow-hidden">
      <div id="back-to-top"></div>
      {/*  Site header */}
      <Header />

      {/*  Page content */}
      <main className="flex-grow pb-12 md:pb-16">

        {/*  Page sections */}
        <HeroHome />
        <FeaturesHome />
        {/* <FeaturesBlocks /> */}
        <Testimonials />
        <GalleryComponent />
      </main>
      
      <Newsletter />

      {/*  Site footer */}
      <Footer />

    </div>
  );
}

export default Home;
import React, { useState, useRef, useEffect } from 'react';
import Transition from '../utils/Transition.js';
import LazyLoad, { forceCheck } from 'react-lazyload';

function Features() {

  const [tab, setTab] = useState(1);

  const tabs = useRef(null);

  const heightFix = () => {
    if (tabs.current.children[tab]) {
      tabs.current.style.height = tabs.current.children[tab - 1].offsetHeight + 'px'
    }
  }

  useEffect(() => {
    heightFix();
    forceCheck();
    scrollSetForElement();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tab]);

  const scrollSetForElement = () => {
    const parentElement = document.getElementById('tabContainer');
    const childElement = document.getElementById('tabId' + tab);
    parentElement.scrollTo({ top: childElement.offsetTop, behavior: "smooth" });
  }

  return (
    <section className="relative select-none">

      {/* Section background (needs .relative class on parent and next sibling elements) */}
      <div className="absolute inset-0 bg-gray-100 pointer-events-none" aria-hidden="true"></div>
      <div className="absolute left-0 right-0 m-auto w-px p-px h-20 bg-gray-200 transform -translate-y-1/2"></div>

      <div className="relative max-w-6xl mx-auto px-4 sm:px-6" id="learn-more">
        <div className="pt-12 md:pt-20">

          {/* Section header */}
          <div className="max-w-3xl mx-auto text-center pb-12 md:pb-16">
            <h1 className="h2 mb-4">Explore our approach</h1>
            {/* <p className="text-xl text-gray-600">Research &amp; development lies at the heart of our workforce, the tech is changing at a very fast pace, we go with the flow</p> */}
          </div>

          <div className="max-w-lg text-center md:text-left sm:text-center mb-8">
            <h3 className="h3 mb-3">A powerful &amp; streamlined workflow</h3>
            <p className="text-xl text-gray-600">Research &amp; Development lingers at the heart of our herd; However, our daily dose of productivity comes from the processes that evolved over the years of experience. See below on how we churn out our work</p>
          </div>

          {/* Section content */}
          <div className="flex flex-col-reverse md:grid md:grid-cols-12 md:gap-6 pb-16">
            {/* Content */}
            <div id="tabContainer" className="overflow-y-auto custom-scroll pr-2 max-w-xl md:max-w-none md:w-full mx-auto md:col-span-12 lg:col-span-12 xl:col-span-6 md:mb-6 relative" data-aos="fade-right">

              {/* Tabs buttons */}
              <a id="tabId1"
                className={`flex items-center text-lg p-5 rounded border transition duration-300 ease-in-out mb-3 ${tab !== 1 ? 'bg-white shadow-md border-gray-200 hover:shadow-lg' : 'bg-gray-200 border-transparent active-tab'}`}
                href="#0"
                onClick={(e) => { e.preventDefault(); setTab(1); }}
              >
                <div>
                  <div className="font-bold leading-snug tracking-tight mb-1">Analysis</div>
                  <div className="text-gray-600">A clear picture of the scope is documented thoroughly in Confluence &amp; flowcharts are designed using Lucid charts</div>
                </div>
                <div className="flex justify-center items-center w-8 h-8 bg-white rounded-full shadow flex-shrink-0 ml-3">
                  <svg xmlns="http://www.w3.org/2000/svg" class="w-5 h-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M7 12l3-3 3 3 4-4M8 21l4-4 4 4M3 4h18M4 4h16v12a1 1 0 01-1 1H5a1 1 0 01-1-1V4z" />
                  </svg>
                </div>
              </a>
              <a id="tabId2"
                className={`flex items-center text-lg p-5 rounded border transition duration-300 ease-in-out mb-3 ${tab !== 2 ? 'bg-white shadow-md border-gray-200 hover:shadow-lg' : 'bg-gray-200 border-transparent active-tab'}`}
                href="#0"
                onClick={(e) => { e.preventDefault(); setTab(2); }}
              >
                <div>
                  <div className="font-bold leading-snug tracking-tight mb-1">Prototyping</div>
                  <div className="text-gray-600">A UX engineer designs wireframes, mockups of the project</div>
                </div>
                <div className="flex justify-center items-center w-8 h-8 bg-white rounded-full shadow flex-shrink-0 ml-3">
                  <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 5a1 1 0 011-1h14a1 1 0 011 1v2a1 1 0 01-1 1H5a1 1 0 01-1-1V5zM4 13a1 1 0 011-1h6a1 1 0 011 1v6a1 1 0 01-1 1H5a1 1 0 01-1-1v-6zM16 13a1 1 0 011-1h2a1 1 0 011 1v6a1 1 0 01-1 1h-2a1 1 0 01-1-1v-6z" />
                  </svg>
                </div>
              </a>
              <a id="tabId3"
                className={`flex items-center text-lg p-5 rounded border transition duration-300 ease-in-out mb-3 ${tab !== 3 ? 'bg-white shadow-md border-gray-200 hover:shadow-lg' : 'bg-gray-200 border-transparent active-tab'}`}
                href="#0"
                onClick={(e) => { e.preventDefault(); setTab(3); }}
              >
                <div>
                  <div className="font-bold leading-snug tracking-tight mb-1">Sprint planning</div>
                  <div className="text-gray-600">A scrum master breaks down project requirements into Epics &amp; Stories, adds them to backlogs in the Jira board</div>
                </div>
                <div className="flex justify-center items-center w-8 h-8 bg-white rounded-full shadow flex-shrink-0 ml-3">
                  <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z" />
                  </svg>
                </div>
              </a>
              <a id="tabId4"
                className={`flex items-center text-lg p-5 rounded border transition duration-300 ease-in-out mb-3 ${tab !== 4 ? 'bg-white shadow-md border-gray-200 hover:shadow-lg' : 'bg-gray-200 border-transparent active-tab'}`}
                href="#0"
                onClick={(e) => { e.preventDefault(); setTab(4); }}
              >
                <div>
                  <div className="font-bold leading-snug tracking-tight mb-1">DevOps workflow setup</div>
                  <div className="text-gray-600">A DevOps engineer architect &amp; configures staging, pre-release &amp; production containers using Docker for “Continuous integration &amp; deployment”</div>
                </div>
                <div className="flex justify-center items-center w-8 h-8 bg-white rounded-full shadow flex-shrink-0 ml-3">
                  <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M14 10l-2 1m0 0l-2-1m2 1v2.5M20 7l-2 1m2-1l-2-1m2 1v2.5M14 4l-2-1-2 1M4 7l2-1M4 7l2 1M4 7v2.5M12 21l-2-1m2 1l2-1m-2 1v-2.5M6 18l-2-1v-2.5M18 18l2-1v-2.5" />
                  </svg>
                </div>
              </a>
              <a id="tabId5"
                className={`flex items-center text-lg p-5 rounded border transition duration-300 ease-in-out mb-3 ${tab !== 5 ? 'bg-white shadow-md border-gray-200 hover:shadow-lg' : 'bg-gray-200 border-transparent active-tab'}`}
                href="#0"
                onClick={(e) => { e.preventDefault(); setTab(5); }}
              >
                <div>
                  <div className="font-bold leading-snug tracking-tight mb-1">Code hosting</div>
                  <div className="text-gray-600">An assigned “Lead Engineer” bootstraps project codes &amp; pushes git repositories to Bitbucket</div>
                </div>
                <div className="flex justify-center items-center w-8 h-8 bg-white rounded-full shadow flex-shrink-0 ml-3">
                  <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M8 9l3 3-3 3m5 0h3M5 20h14a2 2 0 002-2V6a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z" />
                  </svg>
                </div>
              </a>
              <a id="tabId6"
                className={`flex items-center text-lg p-5 rounded border transition duration-300 ease-in-out mb-3 ${tab !== 6 ? 'bg-white shadow-md border-gray-200 hover:shadow-lg' : 'bg-gray-200 border-transparent active-tab'}`}
                href="#0"
                onClick={(e) => { e.preventDefault(); setTab(6); }}
              >
                <div>
                  <div className="font-bold leading-snug tracking-tight mb-1">Sprint starts</div>
                  <div className="text-gray-600">Scrum master finalizes story points at DSU &amp; adds stories to a new sprint as per priority order from the Product owner. Then, sprint starts &amp; engineers take up assigned stories</div>
                </div>
                <div className="flex justify-center items-center w-8 h-8 bg-white rounded-full shadow flex-shrink-0 ml-3">
                  <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 6.253v13m0-13C10.832 5.477 9.246 5 7.5 5S4.168 5.477 3 6.253v13C4.168 18.477 5.754 18 7.5 18s3.332.477 4.5 1.253m0-13C13.168 5.477 14.754 5 16.5 5c1.747 0 3.332.477 4.5 1.253v13C19.832 18.477 18.247 18 16.5 18c-1.746 0-3.332.477-4.5 1.253" />
                  </svg>
                </div>
              </a>
              <a id="tabId7"
                className={`flex items-center text-lg p-5 rounded border transition duration-300 ease-in-out mb-3 ${tab !== 7 ? 'bg-white shadow-md border-gray-200 hover:shadow-lg' : 'bg-gray-200 border-transparent active-tab'}`}
                href="#0"
                onClick={(e) => { e.preventDefault(); setTab(7); }}
              >
                <div>
                  <div className="font-bold leading-snug tracking-tight mb-1">Testing</div>
                  <div className="text-gray-600">Stories completed by the scrum team are parallelly tested by QA engineers on staging, pre-release containers. Automation tests are written by engineers using Cypress, and cross-browser testing is done using Browserstack</div>
                </div>
                <div className="flex justify-center items-center w-8 h-8 bg-white rounded-full shadow flex-shrink-0 ml-3">
                  <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 18h.01M7 21h10a2 2 0 002-2V5a2 2 0 00-2-2H7a2 2 0 00-2 2v14a2 2 0 002 2z" />
                  </svg>
                </div>
              </a>
              <a id="tabId8"
                className={`flex items-center text-lg p-5 rounded border transition duration-300 ease-in-out mb-3 ${tab !== 8 ? 'bg-white shadow-md border-gray-200 hover:shadow-lg' : 'bg-gray-200 border-transparent active-tab'}`}
                href="#0"
                onClick={(e) => { e.preventDefault(); setTab(8); }}
              >
                <div>
                  <div className="font-bold leading-snug tracking-tight mb-1">Deployment</div>
                  <div className="text-gray-600">DevOps engineers create release tags &amp; deploy them to production</div>
                </div>
                <div className="flex justify-center items-center w-8 h-8 bg-white rounded-full shadow flex-shrink-0 ml-3">
                  <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M10 20l4-16m4 4l4 4-4 4M6 16l-4-4 4-4" />
                  </svg>
                </div>
              </a>
            </div>

            {/* Tabs items */}
            <div className="max-w-xl md:max-w-none md:w-full mx-auto md:col-span-12 lg:col-span-12 xl:col-span-6 mb-2 md:mb-0 md:order-1 flex justify-center xl:-mr-16 lg:-mr-0 md:-mr-0 sm:-mr-0" data-aos="zoom-y-out" ref={tabs}>
              <div className="relative flex flex-col text-center lg:text-right">
                {/* Item 1 */}
                <Transition
                  show={tab === 1}
                  appear={true}
                  className="w-full"
                  enter="transition ease-in-out duration-700 transform order-first"
                  enterStart="opacity-0 translate-y-16"
                  enterEnd="opacity-100 translate-y-0"
                  leave="transition ease-in-out duration-300 transform absolute"
                  leaveStart="opacity-100 translate-y-0"
                  leaveEnd="opacity-0 -translate-y-16"
                >
                  <div className="relative inline-flex flex-col">
                    <LazyLoad height={200} placeholder={<div class="sm:p-4 sm:-mt-4 sm:h-64 rounded-2xl flex flex-col sm:flex-row gap-5 select-none .border-transparent" style={{ width: '700px', height: '432px' }}>
                      <div class="h-52 sm:h-full sm:w-full rounded-xl bg-gray-200 animate-pulse"></div></div>}>
                      <img className="pointer-events-none md:max-w-none mx-auto rounded" src={require('../images/Confluence-PH.gif').default} width="700" height="462" alt="Features bg" />
                      {/* <img className="md:max-w-none absolute w-full left-0 transform animate-float" src={require('../images/features-element.png').default} width="500" height="44" alt="Element" style={{ top: '30%' }} /> */}
                    </LazyLoad>
                  </div>
                </Transition>
                {/* Item 2 */}
                <Transition
                  show={tab === 2}
                  appear={true}
                  className="w-full"
                  enter="transition ease-in-out duration-700 transform order-first"
                  enterStart="opacity-0 translate-y-16"
                  enterEnd="opacity-100 translate-y-0"
                  leave="transition ease-in-out duration-300 transform absolute"
                  leaveStart="opacity-100 translate-y-0"
                  leaveEnd="opacity-0 -translate-y-16"
                >
                  <div className="relative inline-flex flex-col">
                    <LazyLoad height={200} scroll={false} placeholder={<div class="sm:p-4 sm:-mt-4 sm:h-64 rounded-2xl flex flex-col sm:flex-row gap-5 select-none" style={{ width: '700px', height: '432px' }}>
                      <div class="h-52 sm:h-full sm:w-full rounded-xl bg-gray-200 animate-pulse"></div></div>}>
                      <img className="pointer-events-none md:max-w-none mx-auto rounded-xl" src={require('../images/xd1.gif').default} width="700" height="462" alt="Features bg" />
                      {/* <img className="md:max-w-none absolute w-full left-0 transform animate-float" src={require('../images/features-element.png').default} width="500" height="44" alt="Element" style={{ top: '30%' }} /> */}
                    </LazyLoad>
                  </div>
                </Transition>
                {/* Item 3 */}
                <Transition
                  show={tab === 3}
                  appear={true}
                  className="w-full"
                  enter="transition ease-in-out duration-700 transform order-first"
                  enterStart="opacity-0 translate-y-16"
                  enterEnd="opacity-100 translate-y-0"
                  leave="transition ease-in-out duration-300 transform absolute"
                  leaveStart="opacity-100 translate-y-0"
                  leaveEnd="opacity-0 -translate-y-16"
                >
                  <div className="relative inline-flex flex-col">
                    <LazyLoad height={200} scroll={false} placeholder={<div class="sm:p-4 sm:-mt-4 sm:h-64 rounded-2xl flex flex-col sm:flex-row gap-5 select-none" style={{ width: '700px', height: '432px' }}>
                      <div class="h-52 sm:h-full sm:w-full rounded-xl bg-gray-200 animate-pulse"></div></div>}>
                      <img className="pointer-events-none md:max-w-none mx-auto rounded-xl" src={require('../images/sprint-plan.gif').default} width="700" height="462" alt="Features bg" />
                      {/* <img className="md:max-w-none absolute w-full left-0 transform animate-float" src={require('../images/features-element.png').default} width="500" height="44" alt="Element" style={{ top: '30%' }} /> */}
                    </LazyLoad>
                  </div>
                </Transition>
                {/* Item 4 */}
                <Transition
                  show={tab === 4}
                  appear={true}
                  className="w-full"
                  enter="transition ease-in-out duration-700 transform order-first"
                  enterStart="opacity-0 translate-y-16"
                  enterEnd="opacity-100 translate-y-0"
                  leave="transition ease-in-out duration-300 transform absolute"
                  leaveStart="opacity-100 translate-y-0"
                  leaveEnd="opacity-0 -translate-y-16"
                >
                  <div className="relative inline-flex flex-col">
                    <LazyLoad height={200} scroll={false} placeholder={<div class="sm:p-4 sm:-mt-4 sm:h-64 rounded-2xl flex flex-col sm:flex-row gap-5 select-none" style={{ width: '700px', height: '432px' }}>
                      <div class="h-52 sm:h-full sm:w-full rounded-xl bg-gray-200 animate-pulse"></div></div>}>
                      <img className="pointer-events-none md:max-w-none mx-auto rounded" src={require('../images/devops.gif').default} width="700" height="462" alt="Features bg" />
                      {/* <img className="md:max-w-none absolute w-full left-0 transform animate-float" src={require('../images/features-element.png').default} width="500" height="44" alt="Element" style={{ top: '30%' }} /> */}
                    </LazyLoad>
                  </div>
                </Transition>
                {/* Item 5 */}
                <Transition
                  show={tab === 5}
                  appear={true}
                  className="w-full"
                  enter="transition ease-in-out duration-700 transform order-first"
                  enterStart="opacity-0 translate-y-16"
                  enterEnd="opacity-100 translate-y-0"
                  leave="transition ease-in-out duration-300 transform absolute"
                  leaveStart="opacity-100 translate-y-0"
                  leaveEnd="opacity-0 -translate-y-16"
                >
                  <div className="relative inline-flex flex-col">
                    <LazyLoad height={200} scroll={false} placeholder={<div class="sm:p-4 sm:-mt-4 sm:h-64 rounded-2xl flex flex-col sm:flex-row gap-5 select-none" style={{ width: '700px', height: '432px' }}>
                      <div class="h-52 sm:h-full sm:w-full rounded-xl bg-gray-200 animate-pulse"></div></div>}>
                      <img className="pointer-events-none md:max-w-none mx-auto rounded" src={require('../images/code-hosting.gif').default} width="700" height="462" alt="Features bg" />
                      {/* <img className="md:max-w-none absolute w-full left-0 transform animate-float" src={require('../images/features-element.png').default} width="500" height="44" alt="Element" style={{ top: '30%' }} /> */}
                    </LazyLoad>
                  </div>
                </Transition>
                {/* Item 6 */}
                <Transition
                  show={tab === 6}
                  appear={true}
                  className="w-full"
                  enter="transition ease-in-out duration-700 transform order-first"
                  enterStart="opacity-0 translate-y-16"
                  enterEnd="opacity-100 translate-y-0"
                  leave="transition ease-in-out duration-300 transform absolute"
                  leaveStart="opacity-100 translate-y-0"
                  leaveEnd="opacity-0 -translate-y-16"
                >
                  <div className="relative inline-flex flex-col">
                    <LazyLoad height={200} scroll={false} placeholder={<div class="sm:p-4 sm:-mt-4 sm:h-64 rounded-2xl flex flex-col sm:flex-row gap-5 select-none" style={{ width: '700px', height: '432px' }}>
                      <div class="h-52 sm:h-full sm:w-full rounded-xl bg-gray-200 animate-pulse"></div></div>}>
                      <img className="pointer-events-none md:max-w-none mx-auto rounded" src={require('../images/sprint-start.gif').default} width="700" height="462" alt="Features bg" />
                      {/* <img className="md:max-w-none absolute w-full left-0 transform animate-float" src={require('../images/features-element.png').default} width="500" height="44" alt="Element" style={{ top: '30%' }} /> */}
                    </LazyLoad>
                  </div>
                </Transition>
                {/* Item 7 */}
                <Transition
                  show={tab === 7}
                  appear={true}
                  className="w-full"
                  enter="transition ease-in-out duration-700 transform order-first"
                  enterStart="opacity-0 translate-y-16"
                  enterEnd="opacity-100 translate-y-0"
                  leave="transition ease-in-out duration-300 transform absolute"
                  leaveStart="opacity-100 translate-y-0"
                  leaveEnd="opacity-0 -translate-y-16"
                >
                  <div className="relative inline-flex flex-col">
                    <LazyLoad height={200} scroll={false} placeholder={<div class="sm:p-4 sm:-mt-4 sm:h-64 rounded-2xl flex flex-col sm:flex-row gap-5 select-none" style={{ width: '700px', height: '432px' }}>
                      <div class="h-52 sm:h-full sm:w-full rounded-xl bg-gray-200 animate-pulse"></div></div>}>
                      <img className="pointer-events-none md:max-w-none mx-auto rounded" src={require('../images/jira-roadmaps_v01.gif').default} width="700" height="462" alt="Features bg" />
                      {/* <img className="md:max-w-none absolute w-full left-0 transform animate-float" src={require('../images/features-element.png').default} width="500" height="44" alt="Element" style={{ top: '30%' }} /> */}
                    </LazyLoad>
                  </div>
                </Transition>
                {/* Item 8 */}
                <Transition
                  show={tab === 8}
                  appear={true}
                  className="w-full"
                  enter="transition ease-in-out duration-700 transform order-first"
                  enterStart="opacity-0 translate-y-16"
                  enterEnd="opacity-100 translate-y-0"
                  leave="transition ease-in-out duration-300 transform absolute"
                  leaveStart="opacity-100 translate-y-0"
                  leaveEnd="opacity-0 -translate-y-16"
                >
                  <div className="relative inline-flex flex-col">
                    <LazyLoad height={200} scroll={false} placeholder={<div class="sm:p-4 sm:-mt-4 sm:h-64 rounded-2xl flex flex-col sm:flex-row gap-5 select-none" style={{ width: '700px', height: '432px' }}>
                      <div class="h-52 sm:h-full sm:w-full rounded-xl bg-gray-200 animate-pulse"></div></div>}>
                      <img className="pointer-events-none md:max-w-none mx-auto rounded" src={require('../images/jira-roadmaps_v01.gif').default} width="700" height="462" alt="Features bg" />
                      {/* <img className="md:max-w-none absolute w-full left-0 transform animate-float" src={require('../images/features-element.png').default} width="500" height="44" alt="Element" style={{ top: '30%' }} /> */}
                    </LazyLoad>
                  </div>
                </Transition>
              </div>
            </div >

          </div >

        </div >
      </div >
    </section >
  );
}

export default Features;
